import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken()
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token
      }
      return config;
    },
    (error) => {
      return Promise.reject(error)
    }
  );

  axiosInstance.interceptors.response.use((response) => {
    return response
  }, function (error) {
    let originalRequest = error.config
    if (error.response.status === 401) {
      originalRequest._retry = 
      localStorage.clear()
      this.$router.push("/login")
      return
    }
    // Do something with response error
    return Promise.reject(error)
  })
}

export default setup