import api from "./api";

class UtilsService {
  /* innovative */

  // getAuthUser() {
  //   return api.get(`/current_employee`);
  // }
  tollTransponders(param) {
    return api.get(`/fetch-toll-transponder${param}`);
  }
  getVehicle(vehicle_id) {
    return api.get(`/toll-transponder/${vehicle_id}`);
 }
  editvehicle(vehicleID, data) {
    return api.post(`/toll-transponder/${vehicleID}?_method=put`,data);
  }
  addvehicle(data) {
    return api.post(`/toll-transponder`,data);
  }
  deletevehicle(vehicleID) {
    return api.delete(`/toll-transponder/${vehicleID}`);
  }
  TransactionsPike() {
    return api.get(`/transactions`);
  }
  GetSubsidiary() {
    return api.get(`/business-units`);
  }
  ReassignTransponder(vehicleID, dataE) {
    return api.post(`/reassign-transponder/${vehicleID}?_method=put`,dataE);
  }
  GetLogs() {
    return api.get(`/client-logs`);
  }
  getAuthUser() {
    return api.get(`/active-user`);
  }
}


export default new UtilsService();
