import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
//Chart js css
require("@/assets/js/plugins/chartjs.min.js");

/* import jQuery/$ for plugins */
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
require("@/assets/css/bootstrap.min.css");
require("@/assets/vendor/fontawesome-free/css/all.min.css");
require("@/assets/css/atlantis.min.css");
require("@/assets/css/bootstrap-datepicker.css");
require("@/assets/js/plugin/webfont/webfont.min.js");
require("@/assets/css/fonts.min.css");

/* JS dependencies */
// <!-- jQuery core -->
require("@/assets/js/core/jquery.3.2.1.min.js");
require("@/assets/js/core/popper.min.js");
require("@/assets/js/core/bootstrap.min.js");
require("@/assets/css/demo.css");

// <!-- jQuery UI -->
require("@/assets/js/plugin/jquery-ui-1.12.1.custom/jquery-ui.min.js");
require("@/assets/js/plugin/jquery-ui-touch-punch/jquery.ui.touch-punch.min.js");

//Datatable Modules
require("@/assets/datatables/css/dataTables.responsive.css");
require("@/assets/datatables/css/buttons.dataTables.min.css");

require("@/assets/js/plugin/datatables/datatables.min.js");
require("@/assets/js/plugin/jquery-scrollbar/jquery.scrollbar.min.js");
require("@/assets/datatables/js/dataTables.responsive.js");
//datatable buttons
require("@/assets/datatables/js/dataTables.buttons.min.js");
require("@/assets/datatables/js/jszip.min.js");
require("@/assets/datatables/js/pdfmake.min.js");
require("@/assets/datatables/js/vfs_fonts.js");
require("@/assets/datatables/js/buttons.html5.min.js");
require("@/assets/datatables/js/buttons.print.min.js");
require("@/assets/datatables/js/buttons.colVis.min.js");
// require("@assets/js/plugin/jquery.sparkline/jquery.sparkline.min.js");
// require("@assets/js/plugin/chart-circle/circles.min.js");
// require("@assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js");
// require("@assets/js/plugin/jqvmap/jquery.vmap.min.js");
// require("@assets/js/plugin/jqvmap/maps/jquery.vmap.world.js");
//chart js
require("@/assets/js/plugin/chart.js/chart.min.js");
//datepicker
require("@/assets/js/bootstrap-datepicker.js");

require('@/assets/js/plugin/sweetalert/sweetalert.min.js');
require("@/assets/js/atlantis.min.js");
// require("@/assets/js/setting-demo2.js");
import WebFont from "./assets/js/plugin/webfont/webfont.min";

// import { FontAwesomeIcon } from "./plugins/font-awesome";
WebFont.load({
  google: {"families":["Lato:300,400,700,900"]},
  custom: {"families":["Flaticon", "Font Awesome 5 Solid", "Font Awesome 5 Regular", "Font Awesome 5 Brands", "simple-line-icons"], 
  },
  active: function() {
    sessionStorage.fonts = true;
  }
});
import setupInterceptors from "./services/setupInterceptors";

setupInterceptors(store);

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  // .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");

