<template>
  <main>
    <div v-if="loading" id="preloader">
      <div class="preloader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="container-fluid 100vh">
      <div class="row">
        <div class="col-sm-4 login-section-wrapper">
          <div class="brand-wrapper">
            <img
              src="assets/images/logo.png"
              class="logo"
              alt="https://www.innovativetoll.com"
            />
          </div>
          <div class="login-wrapper my-auto">
            <h2 class="login-title">Log in</h2>
            <p class="text-gray-400"><em>Log-in to get started</em></p>
            <Form
              @submit="handleLogin"
              :validation-schema="schema"
              class="user"
            >
              <div class="form-group">
                <Field
                  name="email"
                  type="text"
                  class="form-control form-control-user"
                  placeholder="Enter Email Address..."
                />
                <ErrorMessage name="email" class="text-danger p-3" />
              </div>
              <div class="form-group">
                <Field
                  name="password"
                  type="password"
                  class="form-control form-control-user"
                  placeholder="Password"
                />
                <ErrorMessage name="password" class="text-danger p-3" />
              </div>
              <div class="form-group">
                <div v-if="message" class="alert text-danger" role="alert">
                  <b>{{ message }}</b>
                </div>
              </div>
              <button
                class="btn btn-info col-sm-8 rounded-pill"
                :disabled="loading"
              >
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm pr-2"
                ></span>
                <span>Log In</span>
              </button>
            </Form>
            <br />
            <a href="#forgot" class="forgot-password-link">Forgot password?</a>
            <p class="login-wrapper-footer-text">
              Don't have an account?
              <button
                data-toggle="modal"
                @click="tougleModal"
                data-target="#vehicle_upload_modal"
                class="text-primary underline bg-white border border-white special"
              >
                <u>Register here</u>
              </button>
            </p>
            <br />
            <div class="container">
              <div class="copyright ml-auto">
                {{ new Date().getFullYear() }}
                <a href="https://www.innovativetoll.com"
                  >Innovative Toll Solutions</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-8 px-0 d-none d-sm-block"
          style="
            background-image: url(https://crm.innovativetoll.com/assets/images/login_bg.png);
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            overflow: auto;
          "
        >
          <div class="maintxt">
            <h1 class=""><b>INNOVATIVE TOLL</b></h1>
            <span class="overlay-text"
              >Form a relationship with a great toll management solution
              provider <br />
              and set yourself for success in the changing Tolling space
            </span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import AuthService from "@/services/auth.service";
import * as yup from "yup";

export default {
  name: "SignIn",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup.string().required("Password is required!"),
    });
    return {
      loading: false,
        message: "",
        schema,
    };
  },
  methods: {
    async handleLogin(user) {
      this.loading = true;
      this.$store
        .dispatch("auth/login", user)
        .then((response) => {
          this.$router.push("/toll-transponders");
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.message;
        });
        //this.$router.push("/toll-transponders");
    },
  },
};
</script>
<style scoped src="../../assets/css/login.css">
.special {
  cursor: crosshair;
}
</style>
